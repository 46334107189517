<template>
    <v-container>
    <v-layout text-xs-center wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title>System Spoof</v-card-title>
          <v-card-text>
            Allows you to spoof another user.
            <v-autocomplete v-model="userId" :items="users" :search-input.sync="search" label="Search for user to spoof (user must have logged into Portal 3.0 to show up)" item-text="name" item-value="_id"></v-autocomplete>
            <h4>{{ selectedUser.name || '' }}</h4>
            <h5 v-if="userId">User's Roles</h5>
            <v-list dense>
              <v-list-item v-for="role in selectedUser.roles" :key="role">
                <v-list-item-title>{{ role }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-card-actions>
            <v-btn text to="/">Cancel</v-btn>
            <v-btn v-if="userId" text color="success" @click="startSpoof">Start Spoof</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>

</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const search = ref('')
    const users = ref([])
    const userId = ref('')
    const selectedUser = ref({})

    watch(search, (val) => {
      // console.log(val)
      if (val == null) return
      let temp = val.split(',')
      let query = {
        last: { $exists: true },
        $limit: 20,
        $sort: { name: 1 }
      }
      if (!user.value.roles.includes('Technology Services')) query.roles = 'Student'
      if (temp.length > 1) {
        query.last = { $regex: temp[0].trim(), $options: 'i' }
        query.preferred = { $regex: temp[1].trim(), $options: 'i' }
      } else {
        temp = val.split(' ')
        if (temp.length > 1) {
          query.preferred = { $regex: temp[0].trim(), $options: 'i' }
          query.last = { $regex: temp[1].trim(), $options: 'i' }
        } else {
          query.name = { $regex: val.trim(), $options: 'i' }
        }
      }
      root.$feathers.service('users').find({ query }).then(({ data }) => {
        users.value = data
      })
    })
    const startSpoof = () => {
      root.$feathers.service('system/spoof').create({ id: userId.value }).then((user) => {
        root.$store.commit('setUser', user)
        root.$store.commit('setRoles', user.spoof.roles)
        root.$router.push('/')
      })
    }

    watch(userId, (id) => {
      if (id !== '') {
        root.$feathers.service('users').get(id).then((user) => {
          selectedUser.value = user
        })
      }
    })

    return {
      search, users, userId, selectedUser, startSpoof
    }
  }
}
</script>
